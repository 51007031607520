/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/
@font-face {
  font-family:"WorstveldRg";
  src:url("FontWorstveld/394d3819-f1fd-46dc-8364-c4c354e3947b.eot?#iefix");
  src:url("FontWorstveld/394d3819-f1fd-46dc-8364-c4c354e3947b.eot?#iefix") format("eot"),url("FontWorstveld/78dfe9af-4a9e-4851-aeaf-db0bc6183765.woff2") format("woff2"),url("FontWorstveld/7a1f2122-5a8e-45b5-88eb-5f2812efc748.woff") format("woff"),url("FontWorstveld/e29e8b8a-4920-41c4-a0e3-07027687301c.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
